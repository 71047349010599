<template>
  <div id="payment-list">
    <v-card-title class="px-0 pb-6">
      {{ t('Payments') }}
    </v-card-title>

    <v-card>
      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          md="4"
          sm="4"
          class="p-sm-0"
        >
          <div class="d-flex justify-space-between align-center">
            <v-btn
              v-show="isOneDay"
              class="me-1 pa-0 btn-arrows"
              color="primary"
              outlined
              @click="onChangeDay(1)"
            >
              <v-icon size="30">
                {{ icons.mdiChevronLeft }}
              </v-icon>
            </v-btn>
            <v-menu
              v-model="modalCustomDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customDateFilter"
                  :placeholder="t('videos.date')"
                  class="text-body-2"
                  outlined
                  readonly
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateStr"
                color="secondary"
                no-title
                range
                hide-details
                @change="onChangeDay(-1, dateStr)"
              ></v-date-picker>
            </v-menu>
            <v-btn
              v-show="isOneDay"
              class="ms-1 pa-0 btn-arrows"
              color="primary"
              outlined
              @click="onChangeDay(2)"
            >
              <v-icon size="30">
                {{ icons.mdiChevronRight }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="agentFilter"
            :items="agentsOptions"
            item-text="fullname"
            item-value="username"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('transactions.agent')"
          ></v-select>
        </v-col>
        <!-- <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="mediumFilter"
            :items="methodsOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('transactions.payment_medium')"
          ></v-select>
        </v-col> -->
        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="paymentMethodFilter"
            :items="paymentMethodsOptions"
            item-text="name"
            item-value="id"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('transactions.method')"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="statusFilter"
            :items="statusOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('status.status')"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <div class="d-flex flex-column align-end justify-end">
            <v-tooltip
              v-if="hasPermission(130)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <span
                  class="text-body-2 font-weight-light"
                  v-on="on"
                >
                  Total
                  <v-icon
                    v-bind="attrs"
                    small
                    class="ml-1"
                    color="info"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </span>
              </template>
              <span>{{ t('transactions.view_payment') }}</span>
            </v-tooltip>
            <span class="text-h6 font-weight-bold">{{ formatCurrency(computedTotal) }}</span>
          </div>
        </v-col>
      </v-row>
      <v-divider />

      <v-row
        v-if="false"
        class="px-2 ma-0 text-center"
      >
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(128)"
          cols="12"
          md="2"
          sm="12"
          class="d-flex flex-row"
        >
          <v-btn
            color="secondary"
            block
            @click="exportExcel('/payment/file/')"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>

        <v-col
          v-if="hasPermission(129)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        :loading="loading"
        class="text-no-wrap"
        :item-class="() => 'cursor-pointer'"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50, 100]}"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalListTable"
        @click:row="onShow"
      >
        <template #[`item.id`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :style="$vuetify.theme.dark ? 'color: #e7e3fcad' : '#3b0000de' "
            :to="{ name: 'views-pos-payment-preview', params: { id: item.id, dataParams: item, pageParams: options, filterParams: filters } }"
          >
            #{{ item.id }}
          </router-link>
        </template>

        <template #[`item.facility`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.facility.logo ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.facility.logo"
                :src="item.facility.logo"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.facility.facility_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span>
                {{ item.facility.facility_name }}
              </span>
            </div>
          </div>
        </template>

        <template #[`item.payment_date`]="{item}">
          <span class="text-no-wrap">{{ formatStdDate(item.payment_datetime_str, 'datetime-custom', configObjFacility.is12Hour, $i18n.locale) }}</span>
        </template>

        <template #[`item.onsite_payment_method.name`]="{item}">
          {{ item.payment_method.name }}
        </template>

        <template #[`item.guest_name`]="{item}">
          <span class="text-no-wrap">{{ item.guest_name ? item.guest_name : '' }}</span>
        </template>

        <template #[`item.amount`]="{item}">
          <span class="text-no-wrap">{{ formatCurrency(item.invoice_amount_without_fanaty_fee) }}</span>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveClientAvatarVariant(item.status)"
            :class="`${resolveClientAvatarVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status_str }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <v-tooltip
              v-if="hasPermission(130)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="info"
                  v-bind="attrs"
                  :to="{ name: 'views-pos-payment-preview', params: { id: item.id, dataParams: item, pageParams: options, filterParams: filters } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View Payment</span>
            </v-tooltip>

            <v-tooltip
              v-if="hasPermission(131)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="onPrint(item)"
                >
                  <v-icon size="18">
                    {{ icons.mdiPrinter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t('tooltip.print_receivet') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <vue-html2pdf
      v-if="computedPaymentData && computedPaymentData.id"
      v-show="!hideHtml2pdf"
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :enable-links="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="computedPaymentData.id ? `Receipt #${computedPaymentData.id} ${formatStdDate(item.payment_datetime_str, 'datetime-custom', configObjFacility.is12Hour, $i18n.locale)}` : 'Receipt'"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :html-to-pdf-options="{
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
      }"
      @beforeDownload="beforeDownload()"
      @hasDownloaded="hasDownloaded()"
    >
      <section
        slot="pdf-content"
        style="background-color: #ffffff; width: 100%; height: 100%"
      >
        <pos-payment-history-report
          :data-params="computedPaymentData"
          :has-transaction-video="hasProducts"
        ></pos-payment-history-report>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiEyeOutline, mdiPrinter, mdiPlus, mdiChevronLeft, mdiChevronRight, mdiInformationOutline } from '@mdi/js'

import { onMounted, ref, computed } from '@vue/composition-api'
import { getGroupsActive } from '@api'
import { avatarText } from '@core/utils/filter'
import { info, success } from '@core/utils/toasted'
import { formatCurrency, useRouter, formatStdDate } from '@core/utils'
import useCryptoJs from '@/@core/utils/useCryptoJs'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import exportExcel from '@core/utils/useExportExcel'
import moment from 'moment'

import VueHtml2pdf from 'vue-html2pdf'
import PosPaymentHistoryReport from '@/templates/reports/PosPaymentHistoryReport.vue'

import usePaymentList from './usePaymentList'

export default {
  components: {
    VueHtml2pdf,
    PosPaymentHistoryReport,
  },
  setup() {
    const { userData } = useCryptoJs()
    const { route, router } = useRouter()
    const { configObjFacility } = useSelectOptions()
    const {
      listTable,
      totalListTable,
      computedTableColumns,
      searchQuery,
      statusFilter,
      agentFilter,
      mediumFilter,
      paymentMethodFilter,
      dateStr,
      groupFilter,
      dateFrom,
      dateTo,
      maxDate,
      filters,
      options,
      loading,
      descSort,
      modalFrom,
      modalTo,
      agentsOptions,
      methodsOptions,
      paymentMethodsOptions,
      computedTotal,

      t,
      resolveClientAvatarVariant,
      fetchPagedPayments,
      fetchAgents,
      fetchOnSitePaymentMethods,
      hasPermission,
      setFilters,
      setOptions,
    } = usePaymentList()

    const groupsOptions = ref([])
    const hideHtml2pdf = ref(true)
    const html2Pdf = ref(null)
    const initPaymentData = ref({
      id: null,
      amount: null,
      description: null,
      payment_date: null,
      status: null,
      status_str: null,
      facility: {
        facility_name: null,
        logo: null,
        email: null,
        phone: null,
      },
      user: {
        username: null,
        fullname: null,
      },
      user_app: {
        username: null,
        fullname: null,
      },
      terminal_name: null,
      guest_name: null,
      email: null,
      phone_number: null,
      payment_method: {
        id: null,
        name: null,
        info: {
          last4: null,
          brand: null,
          exp_month: null,
          exp_year: null,
        },
      },
      products: [],
      invoice_subtotal: null,
      invoice_discount: null,
      invoice_tax: null,
      invoice_tip: null,
      invoice_amount: null,
      note: null,
    })
    const paymentData = ref(initPaymentData.value)
    const computedPaymentData = computed(() => paymentData.value)
    const hasProducts = computed(() => {
      if (paymentData.value) {
        return !paymentData.value.products.some(e => e.name === null)
      }

      return false
    })

    const modalCustomDate = ref(false)
    const isOneDay = computed(() => (dateStr.value && dateStr.value.length === 1) || (dateStr.value.length === 2 && dateStr.value[0] === dateStr.value[1]))
    const customDateFilter = computed({
      get: () => {
        // if (dateStr.value) return moment(dateStr.value).format('ddd, MMM D, YYYY')
        if (dateStr.value && dateStr.value.length) {
          const [start, end] = dateStr.value
          if (start && end) {
            if (start === end) {
              return moment(start).format('ddd, MMM D, YYYY')
            }

            return `${moment(start).format('ddd, MMM D, YYYY')} - ${moment(end).format('ddd, MMM D, YYYY')}`
          }
          if (start) {
            return moment(start).format('ddd, MMM D, YYYY')
          }

          return ''
        }

        return ''
      },
      set: val => {
        if (val) dateStr.value = val
      },
    })
    const statusOptions = computed(() => [
      { text: t('status.successful'), value: 'S' },
      { text: t('status.declined'), value: 'D' },
      { text: t('status.refunded'), value: 'R' },
    ])

    const generateReport = () => {
      html2Pdf.value.generatePdf()
    }

    const beforeDownload = () => {
      info('Generating PDF!')
    }

    const hasDownloaded = () => {
      success('PDF download successful!')
      paymentData.value = initPaymentData.value
    }

    const onShow = item => {
      router.push({
        name: 'views-pos-payment-preview',
        params: {
          id: item.id,
          dataParams: item,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onPrint = item => {
      paymentData.value = item
      setTimeout(() => {
        generateReport()
      }, 1000)
    }

    const onCreate = () => {
      router.push({
        name: 'views-payment-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onChangeDay = async (next, customDate = null) => {
      let newDay = ''
      if (customDate) {
        dateStr.value = customDate
      } else {
        const [start] = dateStr.value
        if (next === 2) newDay = moment(start).clone().add(1, 'day').format('YYYY-MM-DD')
        else newDay = moment(start).clone().subtract(1, 'day').format('YYYY-MM-DD')
        dateStr.value = [newDay, newDay]
      }

      // if (configFacility.value) await fetchEventNoReason(configFacility.value)
      // await fetchEvents(configFacility.value, newDay)
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      if (userData.value && userData.value.role === 'A') {
        const groups = await getGroupsActive()
        groupsOptions.value = groups.data
      }
      await fetchAgents()
      await fetchOnSitePaymentMethods()
      await fetchPagedPayments()

      setTimeout(() => {
        hideHtml2pdf.value = false
      }, 300)
    })

    return {
      searchQuery,
      statusOptions,
      statusFilter,
      agentFilter,
      paymentMethodFilter,
      mediumFilter,
      groupFilter,
      dateFrom,
      dateTo,
      maxDate,
      filters,
      options,
      descSort,
      totalListTable,
      listTable,
      loading,
      groupsOptions,
      userData,
      computedTableColumns,
      modalFrom,
      modalTo,
      agentsOptions,
      methodsOptions,
      paymentMethodsOptions,
      dateStr,
      modalCustomDate,
      isOneDay,
      customDateFilter,
      computedTotal,
      configObjFacility,

      hideHtml2pdf,
      html2Pdf,
      paymentData,
      computedPaymentData,
      hasProducts,

      // i18n
      t,

      avatarText,
      resolveClientAvatarVariant,
      formatCurrency,
      hasPermission,
      setFilters,
      setOptions,
      fetchPagedPayments,
      onPrint,
      onShow,
      generateReport,
      beforeDownload,
      hasDownloaded,
      onCreate,
      exportExcel,
      onChangeDay,
      formatStdDate,

      icons: {
        mdiEyeOutline,
        mdiPrinter,
        mdiPlus,
        export: require('@/assets/images/svg/export.svg'),
        mdiChevronLeft,
        mdiChevronRight,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss">
.btn-arrows {
  max-width: 40px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  min-height: 40px !important;
}
</style>
<style lang="scss" scoped>
#payment-list {
  .payment-list-actions {
    max-width: 7.81rem;
  }
  .payment-list-search {
    max-width: 10.625rem;
  }
  .payment-list-status {
    max-width: 11.3rem;
  }
}
</style>
